<template>
  <div class="standards">
    <el-card>
      <div slot="header" class="clearfix">
        <el-form :inline="true" ref="form" :model="form" class="demo-form-inline" size="mini">

          <el-form-item label="执行标准号：" prop="hour">
            <el-input v-model="form.standardNo" clearable></el-input>
          </el-form-item>

          <el-form-item class="btn-right">
            <el-button type="primary" @click="query()" :disabled="isLoading">查询</el-button>
          </el-form-item>
          <el-form-item class="btn-right">
            <el-button @click="add()" size="mini" type="success">新增</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-table
          :data="tableData"
          border
          v-loading="isLoading"
          style="width: 100%" size="mini">
          <el-table-column type="index" label="编号" width="80"></el-table-column>
          <el-table-column prop="standardNo" label="执行标准号"></el-table-column>
          <el-table-column prop="name" label="描述"></el-table-column>
          <el-table-column label="操作"  fixed="right">
            <template slot-scope="{row}">
              <el-button @click="edit(row)" type="warning" size="mini" >修改</el-button>
              <el-button @click="del(row)" type="danger" size="mini" >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--      分页组件结构-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dataTotal"
        :disabled="isLoading"
      >
      </el-pagination>

      <div v-if="dialogVisible">
        <el-dialog
          :title="title"
          :visible.sync="dialogVisible"
          width="30%"
          @close="onCancel">
          <div >
            <div>
              <el-form :model="standard" label-position="left" label-width="100px" size="small">
                <el-form-item label="执行标准号：">
                  <el-input v-model="standard.standardNo"></el-input>
                </el-form-item>
                <el-form-item label="描述：">
                  <el-input v-model="standard.name"></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div slot="footer">
              <el-button @click="onCancel">取 消</el-button>
              <el-button type="primary" @click="save">确 定</el-button>
            </div>
          </div>
        </el-dialog>
      </div>

    </el-card>

  </div>
</template>

<script>
import {findStandard,saveStandard,editStandard,getStandardById,delStandardById} from "@/services/product";

export default {
  name: 'standard',
  data() {
    return {
      // 用于存储资源列表数据
      tableData: [],
      form: {
        // 当前显示页号
        currentPage: 1,
        pageSize: 10,
      },
      // 数据总数
      dataTotal: 0,
      isLoading: false,
      dialogType: '',
      dialogVisible: false,
      title: '',
      standard:{},
    }
  },
  methods: {
    // 新增或修改确认按钮
    async save() {
      if (this.dialogType=='add') {
        const {data} = await saveStandard(this.standard);
        if (200== data.code) {
          this.$message.success('操作成功')
          await this.loadStandardByPage()
          this.standard = {}
          this.dialogVisible = false
        }else {
          this.$message.error(data.message)
        }
      }
      if (this.dialogType=='edit') {
        const {data} = await editStandard(this.standard);
        if (200== data.code) {
          this.$message.success('操作成功')
          await this.loadStandardByPage()
          this.standard = {}
          this.dialogVisible = false
        }else {
          this.$message.error(data.message)
        }
      }
    },
    onCancel () {
      this.dialogVisible = false
      this.standard = {}
    },

    // 新增
    add(){
      this.dialogType = 'add'
      this.title = '新增'
      this.dialogVisible = true
    },
    // 修改
    async edit(row){
      this.dialogVisible=true
      this.title='编辑'
      this.dialogType='edit'
      const {data} = await getStandardById(row.id)
      if (data.code ===200) {
        this.standard = data.data
      } else {
        this.$message.error('获取分类信息失败')
      }
    },
    // 删除
    async del(row){
      try {
        await this.showConfirmationDialog(row);
        // 用户点击了确定按钮的逻辑
        await this.remove(row);
      } catch {
        this.$message.info('取消操作')
        // 用户点击了取消按钮的逻辑
        // 可以不做任何操作，或者执行取消操作
      }
    },
    // 删除调用后台接口
    async remove(row) {
      let id = row.id
      const {data} =await delStandardById(id)
      if (200 === data.code) {
        this.$message.success('操作成功')
        await this.loadStandardByPage()
      } else {
        this.$message.error('操作失败')
      }
    },
    // 删除提示弹框
    showConfirmationDialog(row) {
      return new Promise((resolve, reject) => {
        this.$confirm(`是否删除【${row.name}】？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          resolve(); // 用户点击了确定按钮，将Promise解析为成功状态
        }).catch(() => {
          reject(); // 用户点击了取消按钮，将Promise解析为失败状态
        });
      });
    },
    // 每页显示条数变化时触发
    handleSizeChange(val) {
      this.form.pageSize = val
      // 由于修改了每页显示的条数，应当将页数还原默认值1
      this.form.currentPage = 1
      this.loadStandardByPage()
    },

    // 页号改变时触发
    handleCurrentChange(val) {
      this.form.currentPage = val
      this.loadStandardByPage()
    },
    // 添加筛选后的数据查询
    query() {
      // 请求数据前，将请求的页数更新为1
      this.form.currentPage = 1
      this.loadStandardByPage()
    },
    async loadStandardByPage() {
      // 开始加载数据
      this.isLoading = true
      const {data} = await findStandard(this.form)
      if (200 === data.code) {
        this.tableData = data.data.records
        this.dataTotal = data.data.total
      }
      this.isLoading = false
    },

  },
  created() {
    this.loadStandardByPage()
  },
  filters: {

  }
}
</script>

<style lang="scss" scoped>

</style>
